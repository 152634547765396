import { useState, useRef, useEffect } from "react";
import "./footer.scss";

const Footer: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if(!isVisible){
           setIsVisible(entry.isIntersecting);
         }
         
        },
        {
          threshold: 0.5,
        }
      );
  
      if (ref.current) {
        observer.observe(ref.current);
      }
  
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [isVisible]);
    
    return (
        <>
            <div className='footer-hero'>
                <div className='top-pad' ref={ref}>
                <div className={`footer-box ${isVisible && 'animate'}`}></div>
                </div>
                <div className='footer-content' ref={ref}>
                <div className={`footer-content-border  ${isVisible && 'animate'} `}></div>
                <div className={`footer-box`}>
                  <div className={`footer-borders  ${isVisible && 'animate'} `}></div>
                        <img src='../images/cushman.svg' alt='cushman' />
                        <div className='agents'>
                            <div className='agent'>
                                <p>Andy Tyler</p>
                                <a href="mailto:andy.tyler@cushwake.com"><p>andy.tyler@cushwake.com</p></a>
                                <p>020 7152 5250</p>
                                <p>07973 836236</p>
                            </div>
                            <div className='agent'>
                                <p>Marianne Thomas</p>
                                <a href="mailto:marianne.thomas@cushwake.com"><p>marianne.thomas@cushwake.com</p></a>
                                <p>020 7152 5467</p>
                                <p>07771 513118</p>
                            </div>
                            <div className='agent'>
                                <p>Ed Arrowsmith</p>
                                <a href="mailto:ed.arrowsmith@cushwake.com"><p>ed.arrowsmith@cushwake.com</p></a>
                                <p>020 7152 5964</p>
                                <p>07736 869320</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-content" ref={ref}>
                <div className={`footer-box `}>
                <div className={`footer-borders-mis  ${isVisible && 'animate'} `}></div>

                    <p className="misrep">
                    Important notice: 1 . Particulars: these particulars are not an offer or contract, nor part of one. You should not rely on statements by Cushman & Wakefield in the particulars or by word of mouth or in writing (‘information’) as being factually accurate about the property, its condition or its value. Cushman & Wakefield does not have any authority to make any representations about the property, and accordingly any information given is entirely without responsibility on the part of the agents, seller(s) or lessor(s). 2. Photos etc: the photographs show only certain parts of the property as they appeared at the time they were taken. Areas, measurements and distances given are approximate only. 3. Regulations etc: any reference to alterations to, or use of, any part of the property does not mean that any necessary planning, building regulations or other consent has been obtained. A buyer or lessee must find out by inspection or in otherways that these matters have been properly dealt with and that all information is correct. 4. Vat: the vat position relating to the property may change without notice. 
                    </p>
                </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
