export const images = [
    {
        title: "central staircase linking the work floors",
        src: "../images/gallery/1.jpg",
    },
    {
        title: "central staircase linking the work floors",
        src: "../images/gallery/2.jpg",
    },
    {
        title: "central staircase linking the work floors",
        src: "../images/gallery/3.jpg",
    },
    {
        title: "feature stairwell connects the work floors",
        src: "../images/gallery/4.jpg",
    },
    {
        title: "central staircase linking the work floors",
        src: "../images/gallery/6.jpg",
    },
    {
        title: "central staircase linking the work floors",
        src: "../images/gallery/8.jpg",
    },
];
