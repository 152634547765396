import { useEffect, useRef, useState } from "react";
import { images } from "../../utils/home";
import { motion } from "framer-motion";
import Gallery from "../../components/swiper/swiper";
import Footer from "../../components/footer/footer";
import VimeoPlayer from "../../components/vimeoPlayer/vimeoPlayer";
import "../../components/table/table.scss";
import "../../scss/global.scss";
import "./home.scss";

const Home: React.FC = () => {
    window.onload = function () {
        window.scrollTo(0, 0);
    };
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 800);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const [scrolls, setScrolls] = useState(isMobile ? 49 : 60);
    const [scrollsX, setScrollsX] = useState(isMobile ? 50 : 30);
    const [hasScrolled, setHasScrolled] = useState(false);
    const [activeFloorPlan, setActiveFloorPlan] = useState<number>(1);
    const [isSpacePlan, setIsSpacePlan] = useState<boolean>(false);
    const [logoWidth, setLogoWidth] = useState<number>(isMobile ? 50 : 28);
    const [logoPosition, setLogoPosition] = useState<number>(0);
    const [bottomCopy, setBottomCopy] = useState<number>(0);

    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            {
                threshold: 0.5,
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [isVisible]);

    const [isVisible2, setIsVisible2] = useState(false);
    const ref2 = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (!isVisible2) {
                    setIsVisible2(entry.isIntersecting);
                }
            },
            {
                threshold: 0.5,
            }
        );

        if (ref2.current) {
            observer.observe(ref2.current);
        }

        return () => {
            if (ref2.current) {
                observer.unobserve(ref2.current);
            }
        };
    }, [isVisible2]);

    const [isVisible3, setIsVisible3] = useState(false);
    const ref3 = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (!isVisible3) {
                    setIsVisible3(entry.isIntersecting);
                }
            },
            {
                threshold: 0.5,
            }
        );

        if (ref3.current) {
            observer.observe(ref3.current);
        }

        return () => {
            if (ref3.current) {
                observer.unobserve(ref3.current);
            }
        };
    }, [isVisible3]);

    const [isVisible4, setIsVisible4] = useState(false);
    const ref4 = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (!isVisible4) {
                    setIsVisible4(entry.isIntersecting);
                }
            },
            {
                threshold: isMobile ? 0.1 : 0.5,
            }
        );

        if (ref4.current) {
            observer.observe(ref4.current);
        }

        return () => {
            if (ref4.current) {
                observer.unobserve(ref4.current);
            }
        };
    }, [isVisible4]);

    const [isVisible5, setIsVisible5] = useState(false);
    const ref5 = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (!isVisible5) {
                    setIsVisible5(entry.isIntersecting);
                }
            },
            {
                threshold: isMobile ? 0.1 : 0.5,
            }
        );

        if (ref5.current) {
            observer.observe(ref5.current);
        }

        return () => {
            if (ref5.current) {
                observer.unobserve(ref5.current);
            }
        };
    }, [isVisible5]);
    const [isVisible6, setIsVisible6] = useState(false);
    const ref6 = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (!isVisible6) {
                    setIsVisible6(entry.isIntersecting);
                }
            },
            {
                threshold: isMobile ? 0.1 : 0.5,
            }
        );

        if (ref6.current) {
            observer.observe(ref6.current);
        }

        return () => {
            if (ref6.current) {
                observer.unobserve(ref6.current);
            }
        };
    }, [isVisible6]);

    const handleFloorPlan = (floorPlan: number) => {
        setIsSpacePlan(false);
        setActiveFloorPlan(floorPlan);
    };

    useEffect(() => {
        const handleScroll = (event: WheelEvent | TouchEvent) => {
            if (scrollsX < 150) {
                setHasScrolled(true);
                setScrolls((prevScrolls) => prevScrolls + 10);
                setScrollsX((prevScrollsX) => prevScrollsX + 10);
                if (logoWidth !== 14) {
                    setLogoWidth((prevWidth) => prevWidth - 1);
                }
                setLogoPosition((prevPosition) => prevPosition + 1.5);
            } else {
                console.log(bottomCopy);
                setBottomCopy((prevBottomCopy) => prevBottomCopy + 5);
            }
        };

        if (bottomCopy <= (isMobile ? 50 : 45)) {
            window.addEventListener("wheel", handleScroll);
            window.addEventListener("touchmove", handleScroll);
        } else {
            console.log("removing event listener");
            window.removeEventListener("wheel", handleScroll);
            window.removeEventListener("touchmove", handleScroll);
        }

        return () => {
            window.removeEventListener("wheel", handleScroll);
            window.removeEventListener("touchmove", handleScroll);
        };
    }, [scrolls, logoWidth, bottomCopy]);

    useEffect(() => {
        const stopScroll = (event: Event) => {
            event.preventDefault();
        };

        if (bottomCopy <= (isMobile ? 50 : 45)) {
            window.addEventListener("wheel", stopScroll, { passive: false });
            window.addEventListener("touchmove", stopScroll, {
                passive: false,
            });
            window.addEventListener("keydown", function (event) {
                if (
                    [
                        "ArrowUp",
                        "ArrowDown",
                        "ArrowLeft",
                        "ArrowRight",
                    ].includes(event.key)
                ) {
                    event.preventDefault();
                }
            });
        } else {
            window.removeEventListener("wheel", stopScroll);
            window.removeEventListener("touchmove", stopScroll);
            window.removeEventListener("keydown", function (event) {
                if (
                    [
                        "ArrowUp",
                        "ArrowDown",
                        "ArrowLeft",
                        "ArrowRight",
                    ].includes(event.key)
                ) {
                    event.preventDefault();
                }
            });
        }

        return () => {
            window.removeEventListener("wheel", stopScroll);
            window.removeEventListener("touchmove", stopScroll);
            window.removeEventListener("keydown", function (event) {
                if (
                    [
                        "ArrowUp",
                        "ArrowDown",
                        "ArrowLeft",
                        "ArrowRight",
                    ].includes(event.key)
                ) {
                    event.preventDefault();
                }
            });
        };
    }, [bottomCopy]);

    return (
        <>
            <div>
                <div
                    className={`title-mask ${
                        hasScrolled ? "scroll-animation" : ""
                    }`}
                >
                    {/* <div className='touch'>
                        <div className='left'>
                            <p>Get in touch</p>
                        </div>
                        <div className='right'>
                            <img src='../images/hero-arrow.svg' alt='arrow' />
                        </div>
                    </div> */}
                    <img
                        src={`../images/1${isMobile ? "_mb" : ""}.jpg`}
                        className="hero-image"
                        style={{ maskSize: `${scrollsX}vw ${scrolls}vh` }}
                    />
                    <div
                        ref={ref}
                        className={`bottom-lines-wrapper ${
                            hasScrolled && "inActive"
                        }  `}
                    >
                        <div
                            className={`bottom-lines ${isVisible && "animate"}`}
                        >
                            <div className="lines-wrapper" ref={ref}>
                                <div
                                    className={`lines ${
                                        isVisible && "animate"
                                    }`}
                                >
                                    <div className="scroll">
                                        <p>Scroll to enter the square</p>
                                        <img
                                            src="../images/hero-arrow.svg"
                                            alt="scroll"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="title-wrapper"
                        style={{
                            width: `max(${logoWidth}vw, 15vw)`,
                            left: `min(calc(50% - ${
                                logoWidth / 2
                            }vw), calc(50% - 7vw))`,
                            bottom: `${
                                isMobile ? "calc(50%)" : `calc(70% - ${15}vw)`
                            }`,
                            paddingBottom: `${logoPosition}vh`,
                            borderTopLeftRadius: "16vw",
                            borderTopRightRadius: "16vw",
                            ...(hasScrolled && {
                                border: "1px solid white",
                                borderBottom: "0",
                            }),
                        }}
                    >
                        <img src="../images/title.svg" className="title-img" />
                    </div>
                    <div
                        className="location"
                        style={{
                            bottom: `calc(${bottomCopy}vh - 10em - 30vh)`,
                        }}
                    >
                        <h1>A KEY LOCATION</h1>
                        <div className="loc-border"></div>
                    </div>
                </div>
                <section>
                    <div className="copy">
                        <h3>1 Rathbone Square</h3>
                        <p>
                            34,000 - 156,000 sq ft of high-quality office space
                            set over five floors and surrounding a unique
                            enclosed garden square with on-site retail and
                            exceptional employee amenities.
                            <br></br>
                            <br></br>
                            Make the most of a central location where Fitzrovia
                            meets Soho.
                        </p>
                    </div>
                    <Gallery
                        slides={images}
                        classNames={"swiper"}
                        slidesPerView={2}
                    />
                </section>
                <section>
                    <div className="key-features">
                        <div className="title" ref={ref4}>
                            <div
                                className={`title-border ${
                                    isVisible4 && "animate"
                                }`}
                            ></div>
                            <h4>Summary specification</h4>
                            <h3>Key Features</h3>
                        </div>
                        <div className="feature-items">
                            <motion.div
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                                viewport={{ once: true }}
                                className="feature-item-wrapper"
                            >
                                <div className="feature-item">
                                    <img src="../images/features/1.svg" />
                                    <p>230 bike racks</p>
                                </div>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.55 }}
                                viewport={{ once: true }}
                                className="feature-item-wrapper"
                            >
                                <div className="feature-item">
                                    <img src="../images/features/2.svg" />
                                    <p>230 Lockers</p>
                                </div>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.6 }}
                                viewport={{ once: true }}
                                className="feature-item-wrapper"
                            >
                                <div className="feature-item" ref={ref4}>
                                    <div
                                        className={`borders ${
                                            isVisible4 && "animate"
                                        }`}
                                    ></div>
                                    <img src="../images/features/3.svg" />
                                    <p>23 showers</p>
                                </div>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.65 }}
                                viewport={{ once: true }}
                                className="feature-item-wrapper"
                            >
                                <div className="feature-item">
                                    <img src="../images/features/4.svg" />
                                    <p>floor to ceiling heights of 2.8m+</p>
                                </div>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.7 }}
                                viewport={{ once: true }}
                                className="feature-item-wrapper"
                            >
                                <div className="feature-item">
                                    <img src="../images/features/5.svg" />
                                    <p>private and communal terraces</p>
                                </div>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.75 }}
                                viewport={{ once: true }}
                                className="feature-item-wrapper"
                            >
                                <div className="feature-item">
                                    <img src="../images/features/6.svg" />
                                    <p>ability to provide a private entrance</p>
                                </div>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.8 }}
                                viewport={{ once: true }}
                                className="feature-item-wrapper"
                            >
                                <div className="feature-item" ref={ref4}>
                                    <div
                                        className={`borders ${
                                            isVisible4 && "animate"
                                        }`}
                                    ></div>
                                    <img src="../images/features/7.svg" />
                                    <p>fully fitted and furnished</p>
                                </div>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.85 }}
                                viewport={{ once: true }}
                                className="feature-item-wrapper"
                            >
                                <div className="feature-item">
                                    <img src="../images/features/8.svg" />
                                    <p>
                                        4 pipe fan coil <br></br> system ac
                                    </p>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </section>
                <section className="video-section">
                    <div className="video-wrapper">
                        <VimeoPlayer />
                    </div>
                </section>
                <section className="spacer"></section>
                <section>
                    <div className="floorplan">
                        <div className="title">
                            <div
                                className={`title-border ${
                                    isVisible3 && "animate"
                                }`}
                            ></div>
                            <h4>schedule of areas & floorplans</h4>
                            <h2>Central space in a unique place</h2>
                        </div>
                        <div className="table-content" ref={ref3}>
                            <div
                                className={`table-content-border ${
                                    isVisible3 && "animate"
                                }`}
                            ></div>
                            <div className="table">
                                <div className="row">
                                    <div className="cell">
                                        <p>Floor</p>
                                    </div>
                                    <div className="cell">
                                        <p>area (sq ft)</p>
                                    </div>
                                    <div className="cell">
                                        <p>terrace (sq ft)</p>
                                    </div>
                                </div>
                                <a
                                    href="#plans"
                                    className="row floor"
                                    onClick={() => handleFloorPlan(8)}
                                >
                                    <div className="cell">
                                        <p>Communal Terrace</p>
                                    </div>
                                    <div className="cell">
                                        <p></p>
                                    </div>
                                    <div className="cell">
                                        <p>2,539</p>
                                    </div>
                                </a>
                                <a
                                    onClick={() => handleFloorPlan(7)}
                                    href="#plans"
                                    className="row floor"
                                >
                                    <div className="cell">
                                        <p>Sixth Floor</p>
                                    </div>
                                    <div className="cell">
                                        <p>LET</p>
                                    </div>
                                    <div className="cell">
                                        <p>{" "}</p>
                                    </div>
                                </a>
                                <a
                                    href="#plans"
                                    className="row floor"
                                    onClick={() => handleFloorPlan(6)}
                                >
                                    <div className="cell">
                                        <p>Fifth Floor</p>
                                    </div>
                                    <div className="cell">
                                        <p>LET</p>
                                    </div>
                                    <div className="cell">
                                        <p>{" "}</p>
                                    </div>
                                </a>
                                <a
                                    href="#plans"
                                    className="row floor"
                                    onClick={() => handleFloorPlan(5)}
                                >
                                    <div className="cell">
                                        <p>Fourth Floor</p>
                                    </div>
                                    <div className="cell">
                                        <p>LET</p>
                                    </div>
                                    <div className="cell">
                                        <p>{""}</p>
                                    </div>
                                </a>
                                <a
                                    href="#plans"
                                    className="row floor"
                                    onClick={() => handleFloorPlan(4)}
                                >
                                    <div className="cell">
                                        <p>Third Floor</p>
                                    </div>
                                    <div className="cell">
                                        <p>34,011</p>
                                    </div>
                                    <div className="cell">
                                        <p></p>
                                    </div>
                                </a>
                                <a
                                    href="#plans"
                                    className="row floor"
                                    onClick={() => handleFloorPlan(3)}
                                >
                                    <div className="cell">
                                        <p>Second Floor</p>
                                    </div>
                                    <div className="cell">
                                        <p>34,011</p>
                                    </div>
                                    <div className="cell">
                                        <p></p>
                                    </div>
                                </a>
                                <a
                                    href="#plans"
                                    className="row floor"
                                    onClick={() => handleFloorPlan(2)}
                                >
                                    <div className="cell">
                                        <p>First Floor</p>
                                    </div>
                                    <div className="cell">
                                        <p>33,663</p>
                                    </div>
                                    <div className="cell">
                                        <p></p>
                                    </div>
                                </a>
                                <a
                                    href="#plans"
                                    className="row floor"
                                    onClick={() => handleFloorPlan(1)}
                                >
                                    <div className="cell">
                                        <p>Ground floor</p>
                                    </div>
                                    <div className="cell">
                                        <p>10,014</p>
                                    </div>
                                    <div className="cell">
                                        <p>{" "}</p>
                                    </div>
                                </a>
                                <div className="row">
                                    <div className="cell">
                                        <p>Reception & Lounge</p>
                                    </div>
                                    <div className="cell">
                                        <p>6,816</p>
                                    </div>
                                    <div className="cell">
                                        <p>{""}</p>
                                    </div>
                                </div>
                                <a
                                    href="#plans"
                                    className="row floor"
                                    onClick={() => handleFloorPlan(0)}
                                >
                                    <div className="cell">
                                        <p>Lower Ground</p>
                                    </div>
                                    <div className="cell">
                                        <p>39,889</p>
                                    </div>
                                    <div className="cell">
                                        <p>{""}</p>
                                    </div>
                                </a>
                                <div className="row">
                                    <div className="cell">
                                        <p>Storage</p>
                                    </div>
                                    <div className="cell">
                                        <p>4,595</p>
                                    </div>
                                    <div className="cell">
                                        <p>{""}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="cell">
                                        <p>TOTAL</p>
                                    </div>
                                    <div className="cell">
                                        <p>162,999</p>
                                    </div>
                                    <div className="cell">
                                        <p>2,539</p>
                                    </div>
                                </div>
                            </div>
                            <div className="building">
                                <img src="../images/el.png" />
                                <div className="approx">
                                    <p>All Areas Approx - NIA</p>
                                </div>
                            </div>
                        </div>
                        <div className="floorplan-hero" ref={ref5}>
                            <div
                                className={`floorplan-border ${
                                    isVisible5 && "animate"
                                }`}
                            ></div>
                            <div className="floorplan-content">
                                <div
                                    className={`content-border ${
                                        isVisible5 && "animate"
                                    }`}
                                ></div>
                                <div className="key">
                                    <div className="row">
                                        <div className="blue circle" />
                                        <p>Office</p>
                                    </div>
                                    <div className="row">
                                        <div className="grey circle" />
                                        <p>Core area</p>
                                    </div>
                                </div>
                                <img
                                    src="../images/compass.svg"
                                    alt="compass"
                                    className="compass"
                                />
                                <motion.img
                                    id="plans"
                                    src={`../images/floorplans/${
                                        isSpacePlan ? "spacePlans/" : ""
                                    }${activeFloorPlan}.png`}
                                    key={`../images/floorplans/${
                                        isSpacePlan ? "spacePlans/" : ""
                                    }${activeFloorPlan}.png`}
                                    className={`floorplan-img ${
                                        activeFloorPlan === 0 && "lower-ground"
                                    }`}
                                />

                                <div className="btn-wrapper">
                                    <div
                                        className={`btn ${
                                            !isSpacePlan && "active"
                                        }`}
                                        onClick={() => setIsSpacePlan(false)}
                                    >
                                        <p>Floorplan</p>
                                    </div>
                                    <div
                                        className={`btn ${
                                            activeFloorPlan === 8 ||
                                            activeFloorPlan === 0
                                                ? "gray"
                                                : ""
                                        } ${isSpacePlan && "active"}`}
                                        onClick={() =>
                                            activeFloorPlan !== 8 &&
                                            activeFloorPlan !== 0 &&
                                            setIsSpacePlan(true)
                                        }
                                    >
                                        <p>Spaceplan</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="floorplan-btns">
                            <div className="btm-btns">
                                <div
                                    className={`btn ${
                                        activeFloorPlan === 0 && "active"
                                    } `}
                                    onClick={() => handleFloorPlan(0)}
                                >
                                    <p>L Ground</p>
                                </div>
                                <div
                                    className={`btn ${
                                        activeFloorPlan === 1 && "active"
                                    } `}
                                    onClick={() => handleFloorPlan(1)}
                                >
                                    <p>Ground</p>
                                </div>
                                <div
                                    className={`btn ${
                                        activeFloorPlan === 2 && "active"
                                    } `}
                                    onClick={() => handleFloorPlan(2)}
                                >
                                    <p>First</p>
                                </div>
                                <div
                                    className={`btn ${
                                        activeFloorPlan === 3 && "active"
                                    } `}
                                    onClick={() => handleFloorPlan(3)}
                                >
                                    <p>Second</p>
                                </div>
                                <div
                                    className={`btn ${
                                        activeFloorPlan === 4 && "active"
                                    } `}
                                    onClick={() => handleFloorPlan(4)}
                                >
                                    <p>third</p>
                                </div>
                                <div
                                    className={`btn ${
                                        activeFloorPlan === 5 && "active"
                                    } `}
                                    onClick={() => handleFloorPlan(5)}
                                >
                                    <p>fourth</p>
                                </div>
                                <div
                                    className={`btn ${
                                        activeFloorPlan === 6 && "active"
                                    } `}
                                    onClick={() => handleFloorPlan(6)}
                                >
                                    <p>fifth</p>
                                </div>
                                <div
                                    className={`btn ${
                                        activeFloorPlan === 7 && "active"
                                    } `}
                                    onClick={() => handleFloorPlan(7)}
                                >
                                    <p>sixth</p>
                                </div>
                                <div
                                    className={`btn ${
                                        activeFloorPlan === 8 && "active"
                                    } `}
                                    onClick={() => handleFloorPlan(8)}
                                >
                                    <p>terrace</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    );
};

export default Home;
