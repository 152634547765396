import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./swiper.scss";
import { useEffect, useRef, useState } from "react";

interface Slide {
    src: string;
    title: string;
}

interface SwiperProps {
    slides: Slide[];
    classNames: string;
    slidesPerView?: number;
    shadow?: boolean;
    extraClass?: string;
}

const HeroSwiper: React.FC<SwiperProps> = ({
    slides,
    classNames,
    slidesPerView = 1,
    shadow = true,
}) => {
    const [currentSlide, setCurrentSlide] = useState<number>(1);

    const [isVisible6, setIsVisible] = useState(false);
    const ref6 = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (!isVisible6) {
                    setIsVisible(entry.isIntersecting);
                }
            },
            {
                threshold: 0.5,
            }
        );

        if (ref6.current) {
            observer.observe(ref6.current);
        }

        return () => {
            if (ref6.current) {
                observer.unobserve(ref6.current);
            }
        };
    }, []);

    const handleSlideIncrement = () => {
        console.log("slide changed");
        if (currentSlide === slides.length) {
            setCurrentSlide(1);
        } else {
            setCurrentSlide((prevSlide) => prevSlide + 1);
        }
    };
    const handleSlideDecrement = () => {
        console.log("slide changed");
        if (currentSlide === 1) {
            setCurrentSlide(6);
        } else {
            setCurrentSlide((prevSlide) => prevSlide - 1);
        }
    };

    return (
        <>
            <SwiperComponent
                spaceBetween={10}
                className={`${classNames}`}
                pagination={{ clickable: true }}
                modules={[Navigation, Autoplay]}
                navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                }}
                loop={true}
                initialSlide={0}
                centeredSlides={true}
                slidesPerView={1.15}
                effect={"fade"}
                autoplay={{ delay: 5000 }}
                breakpoints={{
                    800: {
                        slidesPerView: slidesPerView,
                        spaceBetween: 50,
                    },
                }}
                onSlideNextTransitionStart={handleSlideIncrement}
                onSlidePrevTransitionStart={handleSlideDecrement}
            >
                {slides.map((slide, index) => (
                    <>
                        <SwiperSlide key={index}>
                            {shadow && <div className='gradient'></div>}
                            <div
                                className='swiper-img-back'
                                style={{ backgroundImage: `url(${slide.src})` }}
                            ></div>
                        </SwiperSlide>
                    </>
                ))}
                <div className='nav-wrapper'>
                    {/* <div className="borders-wrapper "ref={ref6}>
          <div className={`borders ${isVisible6 && "animate"}`} />          </div> */}
                    <div className='nav'>
                        <div className='swiper-button-prev'></div>
                        <div className='copy-wrapper'>
                            {/* <h6>
                {currentSlide}/{slides.length}
              </h6>
              <h6>{slides[currentSlide - 1].title}</h6> */}
                        </div>
                        <div className='swiper-button-next'></div>
                    </div>
                    {/* <div className="borders-wrapper" ref={ref6}>
            <div className={`borders2 ${isVisible6 && "animate"}`} />
          </div> */}
                </div>
            </SwiperComponent>
        </>
    );
};

export default HeroSwiper;
