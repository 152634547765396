import React, { useState } from "react";

const VimeoPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <div style={{ position: "relative", width: "100%", paddingTop: "56.25%" }}>
      <iframe
        id="vimeoIframe"
        src={`https://player.vimeo.com/video/972974165?h=27922813ed&badge=0&autopause=0&transparent=0&player_id=0&app_id=58479&controls=0${
          isPlaying ? "&autoplay=1" : ""
        }`}
        width="1920"
        height="1080"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        title="1 Rathbone Square Film"
      ></iframe>

      {!isPlaying && (
        <div
          onClick={handlePlay}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            cursor: "pointer",
          }}
        ></div>
      )}
    </div>
  );
};

export default VimeoPlayer;
